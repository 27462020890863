<template>
  <b-row :key="componentKey" :class="`p-2 ${rowContainerClass}`">
    <b-col
      :class="`text-center mt-3 ${colContainerClass}`"
      v-for="(input, index) in inputs"
      v-show="input.visible != undefined ? input.visible : true"
      v-bind:key="index"
      :sm="input.sm"
      :md="input.md"
      :lg="input.lg"
      :xl="input.xl"
      :cols="input.cols"
    >
      <!-- <b-skeleton
        v-if="$store.state.isLoading"
        animation="fade"
        height="100%"
        :width="`100%`"
      /> -->

      <CustomInput
        v-model="input.model"
        :modelValue="input.value"
        :id="input.id"
        :valid="null"
        v-on:change-value="alterarFormulario"
        v-on:input-group-button-pressed="execucaoAcaoBotao"
        v-bind="input"
      />
      <p
        class="text-left text-danger card-subtitle p-0 m-0"
        v-if="camposInvalidos.includes(input.id)"
      >
        {{ input.invalidText }}
      </p>
    </b-col>
  </b-row>
</template>

<script>
import Mixin from "../core/Mixin";

export default {
  name: "CustomForm",
  mixins: [Mixin],
  components: {},
  data() {
    return {
      inputs: [],
      dadosFormulario: {},
      componentKey: 1,
      camposInvalidos: [],
    };
  },
  props: {
    dados: {
      type: Object,
      default: {},
    },
    opcoes: {
      type: Array,
      default: [],
    },
    colContainerClass: {
      type: String,
      default: "",
    },
    rowContainerClass: {
      type: String,
      default: "",
    },
  },

  mounted() {
    this.montarEstruturaFormulario();
    this.popularFormulario({ dados: this.dados });
  },

  methods: {
    validarFormulario() {
      this.camposInvalidos = [];
      let isValid = true;

      this.inputs.forEach((input) => {
        if (!input.isValidFunction) return;

        if (input.visible === false) return;

        const value = this.dadosFormulario[input.id];

        const validationFunction = new Function(
          "value",
          `return ${input.isValidFunction};`
        );

        const inputIsValid = validationFunction(value);

        if (!inputIsValid) {
          isValid = false;
          this.camposInvalidos.push(input.id);
        } else {
          const index = this.camposInvalidos.indexOf(input.id);
          if (index !== -1) {
            this.camposInvalidos.splice(index, 1);
          }
        }
      });

      return {
        isValid,
        ...this.camposInvalidos,
      };
    },

    execucaoAcaoBotao(toExecute, inputFormValue) {
      this.$emit("form-input-buttom-pressed", toExecute, inputFormValue);
    },

    limparFormulario() {
      this.inputs.forEach((input) => {
        input.value = undefined;
      });

      this.dadosFormulario = {};

      this.$emit("change-form-values", this.dadosFormulario);

      this.atualizarComponente();
    },

    obterDadosFormulario() {
      return this.dadosFormulario;
    },

    atualizarComponente() {
      this.componentKey += 1;
    },

    montarEstruturaFormulario() {
      this.inputs = this.opcoes;

      this.atualizarComponente();
    },

    alterarFormulario(newValue) {
      let novoValorModel = Object.keys(newValue);

      for (let key of novoValorModel) {
        if (this.dadosFormulario.length > 0) {
          this.dadosFormulario[key] = newValue;
        } else {
          this.dadosFormulario[novoValorModel] = newValue[novoValorModel];
        }
      }

      this.$emit("change-form-values", this.dadosFormulario);
      this.$emit(
        "change-form-values-key-changed",
        this.dadosFormulario,
        this.lodash.get(novoValorModel, "[0]", novoValorModel)
      );

      this.popularFormulario({
        dados: this.dadosFormulario,
        atualizarComponente: false,
      });

      // this.validarFormulario();
    },

    popularFormulario({ dados, atualizarComponente = true }) {
      this.dadosFormulario = dados;

      this.inputs.forEach((input) => {
        const valor = this.dadosFormulario[input.model];

        if (valor !== undefined && valor !== null) {
          if (!input.isMultiselect) {
            // Atualiza o valor para inputs simples
            input.value = valor;
          } else {
            // Atualiza o valor para multiselect
            if (Array.isArray(valor)) {
              input.value = valor.map((item) => ({
                ...item,
                text: this.lodash.get(item, "name", ""),
              }));
            } else {
              input.value = {
                ...valor,
                text: this.lodash.get(valor, "name", ""),
              };
            }
          }
        }
      });

      if (atualizarComponente) {
        this.atualizarComponente();
      }
    },
  },
};
</script>
<style>
</style>
