import Vue from "vue";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "./app.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/main.css";

Vue.use(BootstrapVue);

import "./assets/fontawesome/css/all.css";
import "bootstrap-icons/font/bootstrap-icons.css";

// import './common/variables.scss'

import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
Vue.use(VueSidebarMenu);

import VueLodash from "vue-lodash";
import lodash from "lodash";
Vue.use(VueLodash, { name: "custom", lodash: lodash });

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faUserSecret);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import "font-awesome/css/font-awesome.min.css";
import "vue-navigation-bar/dist/vue-navigation-bar.css";
import Notifications from "vue-notification";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

//defined as global component
Vue.component(
  "font-awesome-icon",
  require("vue-fontawesome-icon/VueFontawesome.vue").default
);

import Timer from "./components/Timer";
import ImageThumb from "./components/ImageThumb";
import Hint from "./components/Hint";
import CustomInput from "./components/CustomInput";
import CustomForm from "./components/CustomForm";
import Navbar from "./components/Navbar";
import Button from "./components/Button";
import GroupButton from "./components/GroupButton";
import Catalog from "./components/Catalog";
import Card from "./components/Card";
import Chart from "./components/Chart";
import SeletorProdutos from "./components/seletores/SeletorProdutos";
import SeletorServicos from "./components/seletores/SeletorServicos";
import Pagamento from "./components/Pagamento";
import ListaCamposPersonalizados from "./components/ListaCamposPersonalizados";
import CustomTable from "./components/Table";
import VueNavigationBar from "vue-navigation-bar";
import Collapse from "./components/Collapse";
import CustomModal from "./components/CustomModal";
import Steps from "./components/Steps";
import App from "./App.vue";

import router from "./router";
import store from "./store";

import Login from "./pages/login/Login";
import axios from "./http/index";
import VueTheMask from "vue-the-mask";
import money from "v-money";

Vue.use(Notifications);
Vue.use(VueTheMask);
Vue.use(money);
Vue.component("Steps", Steps);
Vue.component("CustomInput", CustomInput);
Vue.component("CustomForm", CustomForm);
Vue.component("Collapse", Collapse);
Vue.component("CustomModal", CustomModal);
Vue.component("Card", Card);
Vue.component("ListaCamposPersonalizados", ListaCamposPersonalizados);
Vue.component("Navbar", Navbar);
Vue.component("CustomButton", Button);
Vue.component("GroupButton", GroupButton);
Vue.component("Catalog", Catalog);
Vue.component("Chart", Chart);
Vue.component("SeletorProdutos", SeletorProdutos);
Vue.component("SeletorServicos", SeletorServicos);
Vue.component("Pagamento", Pagamento);
Vue.component("CustomTable", CustomTable);
Vue.component("ImageThumb", ImageThumb);
Vue.component("Timer", Timer);
Vue.component("Hint", Hint);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component("vue-navigation-bar", VueNavigationBar);

Vue.config.productionTip = false;

Vue.prototype.subdominio = Vue.prototype.subdominio;
Vue.prototype.$subdominio = Vue.prototype.$subdominio;

Vue.prototype.$dadosSessao = Vue.prototype.$dadosSessao;

import "./app.scss";

new Vue({
  router,
  store,
  Login,
  axios,
  // AuthMiddleware,
  render: (h) => h(App),
}).$mount("#app");
