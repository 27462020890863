<template>
  <b-row>
    <b-col
      :class="`m-1 container-collapse  text-left rounded-bottom ${classContainer}`"
      cols="12"
    >
      <b-row @click="handleClick" class="container-titulo-claro pt-3 pb-3 m-1">
        <b-col cols="10">
          <h5 class="texto-catalogo">
            {{ title }}
            <b-badge v-if="badgeText" :variant="badgeVariant">
              {{ badgeText }}
            </b-badge>
            <slot name="padrao"></slot>
          </h5>
        </b-col>

        <b-col cols="2" class="d-flex justify-content-end pr-2">
          <b-icon
            class=""
            :icon="isOpened ? 'chevron-bar-up' : 'chevron-bar-down'"
            scale="1.4"
          />
        </b-col>
      </b-row>

      <b-collapse :visible="isOpened" class="p-1 mr-1 container-collapse">
        <slot> </slot>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import Mixin from "../core/Mixin";

export default {
  mixins: [Mixin],
  name: "collapse",
  data: function () {
    return {
      isOpened: false,
    };
  },
  props: {
    disabled: Boolean,
    variant: String,
    title: String,
    contentVisible: Boolean,
    classContainer: String,
    badgeText: String,
    badgeVariant: String,
  },
  mounted() {
    this.isOpened = this.contentVisible;
  },

  methods: {
    handleClick() {
      if (!this.disabled) {
        this.isOpened = !this.isOpened;
        this.$emit("collapse-click");
      }
    },
  },
};
</script>

<style>
.container-titulo-claro {
  background-color: var(--cor-cards) !important;
}

.container-collapse .multiselect__tags {
  background-color: var(--cor-fundo) !important;
}

.container-collapse .b-form-btn-label-control.form-control > .form-control {
  background-color: var(--cor-fundo) !important;
}

.container-collapse .customInput {
  background-color: var(--cor-fundo) !important;
}

.container-collapse .input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  background-color: var(--cor-fundo) !important;
}

.fundo-escuro .container-collapse {
  background-color: var(--cor-fundo) !important;
}

.fundo-escuro {
  background-color: var(--cor-fundo) !important;
}
/* Adicione estilos personalizados aqui, se necessário */
</style>
