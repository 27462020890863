import { render, staticRenderFns } from "./Catalog.vue?vue&type=template&id=91c77ede&scoped=true"
import script from "./Catalog.vue?vue&type=script&lang=js"
export * from "./Catalog.vue?vue&type=script&lang=js"
import style0 from "./Catalog.vue?vue&type=style&index=0&id=91c77ede&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91c77ede",
  null
  
)

export default component.exports