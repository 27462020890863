<template lang="html">
  <div v-if="isEnded">
    Ended.
  </div>

  <div v-else>
    <p class="text-nofocus">{{ label }} {{ formattedTime }}</p>
  </div>
</template>

<script>
export default {
  props: {
    endDate: {
      type: Date,
      required: true,
    },
    label: {
      type: String,
      default: "Tempo restante",
    },
  },
  data() {
    return {
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      isEnded: false,
    };
  },
  computed: {
    formattedTime() {
      return `${this.formatNumber(this.hours)}:${this.formatNumber(
        this.minutes
      )}:${this.formatNumber(this.seconds)}`;
    },
  },
  methods: {
    formatNumber(value) {
      return value < 10 ? `0${value}` : value;
    },
    updateRemaining(distance) {
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    },
    tick() {
      const currentTime = new Date();
      const distance = Math.max(this.endDate - currentTime, 0);
      this.updateRemaining(distance);

      if (distance === 0) {
        clearInterval(this.timer);
        this.isEnded = true;
      }
    },
  },
  mounted() {
    this.tick();
    this.timer = setInterval(this.tick.bind(this), 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
/* Estilos opcionais */
</style>