/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import validarSessao from "../middlewares/Auth";
import UsersService from "../services/users";

Vue.use(VueRouter);
const DEFAULT_TITLE = "Skedoole";
const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/seja-membro",
    name: "Seja Membro",
    meta: {
      requiresAuth: false,
      layoutCompleto: false,
    },
    component: () => import("../pages/novaEmpresa/NovaEmpresa"),
  },
  {
    path: "/estabelecimentos",
    name: "Estabelecimentos",
    meta: {
      requiresAuth: false,
      layoutCompleto: false,
    },
    component: () => import("../pages/estabelecimento/SelecaoEstabelecimento"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      requiresAuth: false,
      layoutCompleto: false,
    },
    component: () => import("../pages/login/Login"),
  },
  {
    path: "/configuracoes",
    name: "Painel Admin",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/painelAdmin/Painel"),
  },
  {
    path: "/selecaoDiaMes",
    name: "Selecao dia mes",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/agendamentos/SelecaoDiaMesAgendamento"),
  },
  {
    path: "/selecaoHora",
    name: "Selecao Hora",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/agendamentos/SelecaoHoraAgendamento"),
  },
  {
    path: "/servicos",
    name: "Servicos",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/servicos/Servicos"),
  },
  {
    path: "/configuracoes-servicos",
    name: "Listagem Servicos",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/servicos/ListagemServicos"),
  },
  {
    path: "/cadastroServico",
    name: "Cadastro Servico",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/servicos/CadastroServico"),
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/home/Home"),
  },
  {
    path: "/agenda",
    name: "Horarios Agendamentos",
    props: true,
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/agendamentos/HorariosAgendamento"),
  },
  {
    path: "/detalhesAgendamento",
    name: "Detalhes Agendamento",
    props: true,
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/agendamentos/DetalhesAgendamento"),
  },
  {
    path: "/cadastroAgendamento",
    name: "Cadastro Agendamento",
    props: true,
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/agendamentos/CadastroAgendamento"),
  },

  {
    path: "/agendamentos",
    name: "Meus Agendamentos",
    props: true,
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/agendamentos/MeusAgendamentos"),
  },

  {
    path: "/prestadores",
    name: "Prestadores",
    props: true,
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/agendamentos/SelecaoPrestadorServico"),
  },

  {
    path: "/cadastroProduto",
    name: "Cadastro Produto",
    props: true,
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/produtos/CadastroProdutos"),
  },
  {
    path: "/configuracoes-produtos",
    name: "Listagem Produtos",
    props: true,
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/produtos/ListagemProdutos"),
  },
  {
    path: "/cadastroUsuario",
    name: "Cadastro Usuario",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/usuarios/CadastroUsuarios"),
  },
  {
    path: "/configuracoes-usuarios",
    name: "Lista Usuarios",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/usuarios/ListagemUsuarios"),
  },
  {
    path: "/dashboard-financeiro",
    name: "Financeiro",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/financeiro/Financeiro"),
  },
  {
    path: "/configuracoes-grupos",
    name: "Listagem Grupos",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/grupos/ListagemGrupos"),
  },
  {
    path: "/cadastroGrupos",
    name: "Cadastro Grupos",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/grupos/CadastroGrupos"),
  },
  {
    path: "/configuracoes-cadastro-assinaturas",
    name: "Cadastro Informacoes Plano",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/planos/Planos"),
  },
  {
    path: "/configuracoes-assinaturas",
    name: "Listagem Planos",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/planos/ListagemPlanosAdmin"),
  },
  {
    path: "/listagem-assinaturas",
    name: "Listagem planos",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/planos/ListagemPlanos"),
  },
  {
    path: "/configuracoes-horario-funcionamento",
    name: "Horários Funcionamento",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () =>
      import("../pages/horariosFuncionamento/HorariosFuncionamento"),
  },
  {
    path: "/configuracoes-unidades",
    name: "Listagem Filiais",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/filiais/ListagemFiliais"),
  },
  {
    path: "/cadastro-unidades",
    name: "Cadastro Filiais",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/filiais/CadastroFiliais"),
  },
  {
    path: "/configuracoes-cobrancas",
    name: "Cobranças",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/cobrancas/Cobrancas"),
  },
  {
    path: "/fila",
    name: "Fila",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/fila/Fila"),
  },
  {
    path: "/checkout",
    name: "Checkout",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/checkout/Checkout"),
  },
  {
    path: "/transfers",
    name: "Transferências",
    meta: {
      requiresAuth: true,
      layoutCompleto: true,
    },
    component: () => import("../pages/transferencias/Transferencias"),
  },
];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const router = new VueRouter({
  mode: "history",
  routes,
});

async function atualizarDadosSessao(toRoute) {
  if (toRoute.name == "Login") {
    Vue.prototype.$dadosSessao = {};
  }

  let storeUsuarioLogadoAtual = Vue.prototype.$dadosSessao;

  if (
    toRoute.meta.requiresAuth &&
    (!storeUsuarioLogadoAtual ||
      Object.keys(storeUsuarioLogadoAtual).length == 0)
  ) {
    let dadosStorage = JSON.parse(localStorage.getItem("dadosUsuarioLogado"));
    let retorno = await UsersService.obterUsuarioSessao();

    let dadosStoreAtualizados = {
      ...dadosStorage[0],
      user: {
        ...retorno.data.user,
      },
    };

    Vue.prototype.$dadosSessao = dadosStoreAtualizados;
  }
}

function atualizarHistoricoRotas(from, to) {
  // Inicializa o histórico de rotas (Funcionamento dos breadcumbs)
  let rotaExistente = undefined;

  if (Vue.prototype.$routeHistory) {
    rotaExistente = Vue.prototype.$routeHistory.find(
      (route) => route.text == to.name
    );
  }

  Vue.prototype.$routeHistory = [];

  if (to.name === "Home") {
    // Se a rota for Home, inicializa com valores vazios
    Vue.prototype.$routeHistory.push({
      text: "",
      to: "",
    });
  } else {
    // Adiciona o link para a página inicial
    Vue.prototype.$routeHistory.push({
      text: "Inicio",
      to: "/home",
    });

    // Adiciona a rota anterior, se não for a página inicial
    if (from.path !== "/" && from.name !== "Home" && !rotaExistente) {
      Vue.prototype.$routeHistory.push({
        text: from.name,
        to: from.path,
      });
    }

    // Adiciona a nova rota, se não for a página inicial
    if (to.path !== "/" && to.name !== "Home") {
      Vue.prototype.$routeHistory.push({
        text: to.name,
        to: to.path,
      });
    }
  }
}

router.beforeEach(async (to, from, next) => {
  await atualizarDadosSessao(to);
  const SESSAO_VALIDA = await validarSessao();
  let isLocalhost = window.location.href.includes("localhost");
  const isAppDomain = window.location.href.includes("app.skedoole.com.br");

  if (!isLocalhost) {
    const urlPattern = /https:\/\/(.+?)\.skedoole\.com\.br/;
    const match = window.location.href.match(urlPattern);
    if (match && match[1]) {
      Vue.prototype.$subdominio = match[1];
    }
  }

  atualizarHistoricoRotas(from, to);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!SESSAO_VALIDA) {
      next({ path: "/login" });
      return;
    }
    next();
    return;
  }

  if (
    (to.name === "Estabelecimentos" || to.name === "Seja Membro") &&
    !isAppDomain
  ) {
    if (isLocalhost) {
      next();
      return;
    }
    window.location.href = `https://app.skedoole.com.br${to.path}`;
    return;
  }

  if (
    !Vue.prototype?.$subdominio &&
    to.name !== "Estabelecimentos" &&
    to.name !== "Seja Membro"
  ) {
    if (isLocalhost) {
      next("/estabelecimentos");
      return;
    }
    window.location.href = "https://app.skedoole.com.br/estabelecimentos";
    return;
  }

  next();
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    let dadosUsuarioLogado = JSON.parse(
      localStorage.getItem("dadosUsuarioLogado")
    );

    document.title = dadosUsuarioLogado
      ? capitalizeFirstLetter(dadosUsuarioLogado[0].nomeEmpresa)
      : DEFAULT_TITLE;
  });
});

export default router;
