<template>
  <b-row
    @click="imagemSelecionada"
    :key="componentKey"
    :class="`${rowContainerClass}`"
  >
    <b-col :sm="sm" :md="md" :lg="lg" :xl="xl" :cols="cols">
      <b-img-lazy
        class="cursor-pointer"
        :rounded="rounded ? 'circle' : false"
        :thumbnail="thumbnail"
        :src="
          srcImagem && srcImagem != null
            ? srcImagem
            : require('../assets/sem_imagem.png')
        "
        :width="largura"
        :height="altura"
      >
      </b-img-lazy>
    </b-col>

    <b-modal
      id="modal-detalhes-imagem"
      ref="modal-detalhes-imagem"
      size="lg"
      modal-class="custom-modal"
      centered
      scrollable
      hide-header
      title="Detalhes imagem"
      body-class="container-detalhes-modal-options"
      footer-class="container-detalhes-modal-options"
      header-class="container-detalhes-modal-options"
    >
      <b-col class="text-center mt-3">
        <b-img-lazy
          :src="
            srcImagem && srcImagem != null
              ? srcImagem
              : require('../assets/sem_imagem.png')
          "
          :blank="true"
        >
        </b-img-lazy>
      </b-col>

      <template #modal-footer>
        <CustomButton
          buttonText="Fechar"
          variant="light"
          @button-click="fecharModal"
        />
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import Mixin from "../core/Mixin";

export default {
  name: "GroupButton",
  mixins: [Mixin],
  components: {},
  data() {
    return {
      srcImagem: "",
      componentKey: 1,
    };
  },
  props: {
    rounded: {
      type: Boolean,
      default: true,
    },
    thumbnail: {
      type: Boolean,
      default: true,
    },
    hasDetailsModal: {
      type: Boolean,
      default: true,
    },
    src: {
      type: String,
    },
    rowContainerClass: {
      type: String,
    },
    altura: {
      type: Number,
      default: 135,
    },
    largura: {
      type: Number,
      default: 135,
    },
    cols: {
      type: Number,
      default: 12,
    },
    sm: {
      type: Number,
      default: 12,
    },
    md: {
      type: Number,
      default: 12,
    },
    lg: {
      type: Number,
      default: 12,
    },
    xl: {
      type: Number,
      default: 12,
    },
  },

  mounted() {
    this.montarEstrutura();
  },

  methods: {
    imagemSelecionada() {
      if (this.hasDetailsModal) {
        this.exibirModal();
      }

      this.$emit("image-selected", this.src);
    },

    atualizarComponente() {
      this.componentKey += 1;
    },

    montarEstrutura() {
      this.srcImagem = this.src;
    },

    exibirModal(nomeModal = "modal-detalhes-imagem") {
      if (this.$refs[nomeModal]) {
        this.$refs[nomeModal].show();
      }
    },

    fecharModal(nomeModal = "modal-detalhes-imagem") {
      if (this.$refs[nomeModal]) {
        this.$refs[nomeModal].hide();
      }
    },
  },
};
</script>
<style>
</style>
